<template>
  <ProfileOrgWrapper>
    <router-view />
  </ProfileOrgWrapper>
</template>

<script>
export default {
  name: 'ProfileOrgSettingsView'
}
</script>
